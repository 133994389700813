export const isValidEmail = (email) => {
  let emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
};

export const isValidPassword = (password) => {
  return password.length > 0;
};

export const isEmpty = (inputValue) => {
  return inputValue.length > 0;
};

export const onClickFocus = (e) => {
  let input = e.target;
  let value = input.value;
  if (value === '' || value === undefined || value === null){
    input.focus();
  }
};