// Utils
import { gSubdomain } from './config';
import { msalInstance } from '../index';
import { msalConfig } from './msalConfig';

export const haveAccessToken = () => {
  const token = localStorage.getItem(`accessToken2.${gSubdomain}`);
  if (!token) {
    console.info(`session.haveAccessToken() returning false, no token`);
    return false; // no token
  }

  //let tokenInfo;
  try {
    //tokenInfo = JSON.parse(token);
    JSON.parse(token);
  } catch (err) {
    console.error(`session.haveAccessToken() failed: ${err}`);
    return false; // malformed token
  }

  /**
   * For now removing logic to check for expired tokens. Corning refreshes the IDgo Agent browser after each
   * phone call. Checking for expired tokens causes the browser to navigate to the sign in screen at the
   * access token expiration interval.
   * 
   * It may make sense to remove the PrivateRoute.js component all together...
   * 
  const decodedToken = jwtDecode(tokenInfo.token);
  const { exp } = decodedToken;
  const expirationSeconds = exp - 5; // token expiration in seconds (minus 5)
  const expirationMS = expirationSeconds * 1000;
  const currentMS = Date.now();
  if (currentMS >= expirationMS) {
    console.debug(`session.haveAccessToken() returning false, token exp: "${new Date(exp*1000).toISOString()}", current timestamp: ${new Date(currentMS).toISOString()}`);
    return false; // token is expired or going to expire within 5 seconds
  }
  */
  return true;
};

export const setAccessToken = (type, token) => {
  if (!type) {
    localStorage.removeItem(`accessToken2.${gSubdomain}`);
  } else {
    localStorage.setItem(`accessToken2.${gSubdomain}`, JSON.stringify({type, token}));
  }
};

export const getAccessToken = async () => {
  const token = localStorage.getItem(`accessToken2.${gSubdomain}`);
  if (!token) return {token:null};
  try {
    const tokenInfo = JSON.parse(token);
    if (tokenInfo.type==='cozera') return tokenInfo;

    const account = msalInstance.getActiveAccount();
    if (!account) {
      console.error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
      return {token:null};
    }

    console.debug(`session.getAccessToken() Fetching a AAD idToken for { environment: ${account?.environment}, tenantId: ${account?.tenantId}, username: ${account?.username} }`);

    // to get an accessToken returned, you need to include the client_id and openid or profile.
    const msalRequest = { scopes: [`api://${msalConfig.auth.clientId}/IDgo.Agent`] };
    const idToken = await msalInstance.acquireTokenSilent({...msalRequest, account})
      .then((resp) => {
        return resp.idToken;
      })
      .catch((err) => {
        console.error(`session.js msalInstance.acquireTokenSilent() failed: ${JSON.stringify(err,null,4)}`);
        if (err?.errorCode==='interaction_required') {
          const redirectResult = msalInstance.acquireTokenRedirect(msalRequest);
          return redirectResult.idToken;
        }
      });

    if (idToken) {
      console.debug(`session.getAccessToken() returning AAD idToken: ${idToken}`);
      // no need to store the AAD token, masl takes care of this for us.
      localStorage.setItem(`accessToken2.${gSubdomain}`, JSON.stringify({type:'aad', token:undefined}));
      return {type:'aad', token:idToken};
    }
  } catch (err) {
    console.error(`session.getAccessToken() failed: ${JSON.stringify(err,null,4)}`);
  }
  // errors logged on both catch blocks
  return {token:null};
};

export const getTokenType = () => {
  const token = localStorage.getItem(`accessToken2.${gSubdomain}`);
  try {
    const tokenInfo = JSON.parse(token);
    return tokenInfo.type;
  } catch (err) {
    console.error(`session.getTokenType() failed: ${JSON.stringify(err,null,4)}`);
    return;
  }
};

export const setRefreshToken = (refreshToken) => {
  if (!refreshToken) {
    localStorage.removeItem(`refreshToken.${gSubdomain}`);  
  } else {
    localStorage.setItem(`refreshToken.${gSubdomain}`, refreshToken);
  }
};
export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem(`refreshToken.${gSubdomain}`);
  return refreshToken;
};

export const setUser = (user) => {
  if (!user) {
    localStorage.removeItem(`userData.${gSubdomain}`);  
  } else {
    localStorage.setItem(`userData.${gSubdomain}`, JSON.stringify(user));
  }
};
export const getUser = () => {
  const user = localStorage.getItem(`userData.${gSubdomain}`);
  try {
    return JSON.parse(user);
  } catch (err) {
    return null;
  }
};

export const haveRefreshToken = () => {
  const refreshToken = localStorage.getItem(`refreshToken.${gSubdomain}`);
  if (!refreshToken) return false;
  return true;
};

export const setWindowSize = (name, windowSize) => {
  localStorage.setItem(name, JSON.stringify(windowSize));
};

export const getWindowSize = (name) => {
  const windowSize = localStorage.getItem(name);
  try {
    return JSON.parse(windowSize);
  } catch (err) {
    return undefined;
  }
};
