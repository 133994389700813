import React from "react";
import { createRoot } from 'react-dom/client';

// Styles
import "./index.css";

// Components
import App from "./App";

import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-mui';

import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './utils/msalConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

console.debug(`index.js - msalConfig: ${JSON.stringify(msalConfig,null,4)}`);

const alert_opts = {
  position: positions.MIDDLE
}

const container = document.getElementById('app');
const root = createRoot(container);

msalInstance.initialize()
  .then(() => {
    const activeAccount = msalInstance.getActiveAccount();
    const allAccounts = msalInstance.getAllAccounts();
    // Default to using the first account if no account is active on page load
    if (!activeAccount && allAccounts.length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(allAccounts[0]);
    }
    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();
    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    });

    root.render(
      <AlertProvider template={AlertTemplate} {...alert_opts}>
        <App instance={msalInstance}/>
      </AlertProvider>
    );
  })
  .catch((_err) => {
    root.render(
      <AlertProvider template={AlertTemplate} {...alert_opts}>
        <App />
      </AlertProvider>);
  });