import { LogLevel } from "@azure/msal-browser";
import { gSubdomain, getRelyingParty } from './config';

const rpInfo = await getRelyingParty();

console.debug(`msalConfig(): tenantId: ${rpInfo?.data?.aadTenantId}, clientId: ${rpInfo?.data?.aadClientId}`)

// ref: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
export const msalConfig =
{ auth:
  { clientId                  : rpInfo?.data?.aadClientId
  , authority                 : `https://login.microsoftonline.com/${rpInfo?.data?.aadTenantId}`
  , postLogoutRedirectUri     : `${window.location.origin}/#${gSubdomain}`
  , navigateToLoginRequestUrl : false
  }
, cache:
  { cacheLocation             : 'sessionStorage'
  , storeAuthStateInCookie    : false
  }
, system:
  { loggerOptions:
    { loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) 
        { case LogLevel.Error:
            console.error(`msalConfig.loggerCallback() ${message}`);
            return;
          case LogLevel.Warning:
            console.warn (`msalConfig.loggerCallback() ${message}`);
            return;
          case LogLevel.Info:
          case LogLevel.Verbose:
            return;
          default:
            console.log  (`msalConfig.loggerCallback() ${message}`);
            return;
        }
      }
    }
  }
};