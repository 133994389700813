// This helper was created to help us to address MVP-1895
// This helper is to be aware of the current taken call of the logged user
// it is not publishing the status or changing it in pubnub
let callInProgress = null;

export const setCallInProgress = (call) => {
  callInProgress = call;
};

export const getCallInProgress = (call) => {
  return callInProgress;
};

export const isCallInProgress = () => {
  return (callInProgress !== undefined && callInProgress !== null);
};
