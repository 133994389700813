import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PubNubReact from 'pubnub';
import { useAlert } from 'react-alert';

//Context
import { useRelyingParty } from '../../context/RelyingPartyContext';

// Utils
import { ctxValue } from '../../utils/config';
import { getAccountData } from '../../utils/services';

//Styles
import styles from './PendingAuthentication.module.css';

//Images
import processing from './processing.gif';

const pubnub = new PubNubReact({
  subscribe_key: ctxValue('PUBNUB_SUBSCRIBE_KEY'),
  ssl: true,
  uuid: 'call-center-app',
});

let lastPubnubResult = '';

const PendingAuthentication = (props) => {
  const history = useHistory();
  const alert = useAlert();
  const {relyingParty} = useRelyingParty();

  //console.debug(`PendingAuthentication() relyingParty: ${JSON.stringify(relyingParty,null,3)}`);

  /**
   * Post a messqage callback if this enterprise has a value defined for 'agentPopupOrigin' in the relying_party.config table
   */
  const postMessageCallback = (data) => {
    if (window?.opener) { // for poped up windows
      if (relyingParty?.agentPopupOrigin) {
        window.opener.postMessage({...data, source:'IDgoAgent'}, relyingParty.agentPopupOrigin);
        return;
      }
      console.warn(`PendingAuthentication: relyingParty.agentPopupOrigin not set - unable to postMessage to opener`);
    }
    if (window?.parent) { // for iframed up windows
      console.warn(`PendingAuthentication: posting data: ${JSON.stringify(data,null,3)}`);
      window.parent.postMessage({...data, source:'IDgoAgent'}, '*');
      return;
    }
  };

  useEffect(() => {
    const channel = `authnotify.${props.authContext}`;
    const listener = {
      status: (st) => {
        if (st.category === 'PNUnknownCategory') {
          const newState = { new: 'error' };
          pubnub.setState( { state: newState }, (_status) => { 
            console.debug(`PendingAuthentication.useEffect() pubnub.setState() st: ${JSON.stringify(st.errorData.message,null,3)}`);
          });
        }
      },
      message: (msg) => {
        // check for and remove duplicates
        if (lastPubnubResult===msg.message.authcontext) {
          console.debug(`PendingAuthentication.useEffect() dropping duplicate authContext event: ${lastPubnubResult}`);
          return;
        }

        lastPubnubResult = msg.message.authcontext;
        pubnub.unsubscribe({ channels: [`authnotify.${msg.message.authcontext}`] });

        switch (msg.message.result) {
          case 'pass':
            presentCallerInformation(props.mobileNumber);
            break;
          
          case 'rejected':
            postMessageCallback({result: msg.message.result});
            alert.show(<div>User declined this Authenticate request</div>, { type: 'error', title: 'Declined', onClose: () => { onCancelButtonClick(); } });
            break;

          case 'fail':
            postMessageCallback({result: msg.message.result});
            alert.show(<div>Authenticate request failed</div>, { type: 'error', title: 'System Error', onClose: () => { onCancelButtonClick(); } });
            break;

          default:
            postMessageCallback({result: msg.message.result});
            alert.show(<div>Authenticate request exception</div>, { type: 'error', title: 'System Error', onClose: () => { onCancelButtonClick(); } });
        }
      },
    };
    pubnub.addListener(listener);
    pubnub.subscribe({ channels: [channel] });

    return () => {
      pubnub.unsubscribe({ channels: [channel] });
      pubnub.removeAllListeners(); // required even though it shows as "may not exist" (or you will get duplicates)
    };
    // eslint-disable-next-line
  }, []);

  const presentCallerInformation = async (mobileNumber) => {
    const result = await getAccountData(mobileNumber);
    if (result?.status===200) {
      const callWithClaimData = { mobileNumber, callerData: { agentClaimData: result.data.agentClaimData } };
      postMessageCallback({result: 'pass', data: result.data.agentClaimData});
      history.push({ pathname: '/caller-information', hash: ctxValue('SUBDOMAIN'), state: { call: callWithClaimData, isOnDemand: true } });
      return;
    }
    postMessageCallback({result: undefined});
    alert.show(<div>Authenticate result error</div>, { type: 'error', title: 'System Error', onClose: () => { onCancelButtonClick(); } });
  };

  const onCancelButtonClick = () => {
    pubnub.unsubscribe({ channels: [`authnotify.${props.authContext}`] });
    props.cancelAuthentication();
  };

  return (
    <Fragment>
      <div className='text-center'>
        <div className={styles.authHeader}>
          Authentication in progress
          <img alt={'processing...'} src={processing} className={styles.processingImage}></img>
        </div>
        <input type='button' className='btn btn-outline-danger btn-lg shadow-none' value='Cancel' onClick={ onCancelButtonClick } />
      </div>
    </Fragment>
  );
};

export default PendingAuthentication;