import React, { Fragment } from "react";

// Utils
import { ctxValue } from '../../utils/config';

const OnDemandFailure = (props) => {
  const { message, iconName, altText } = props.location.state;

  const onClick = (e) => {
    e.preventDefault();
    props.history.push({ pathname: '/caller-queue', hash: ctxValue('SUBDOMAIN') });
  };

  return (
    <Fragment>
      <div className="text-center" style={{ marginTop: "40px" }}>
        <p style={{ marginBottom: "25px" }}>
          <img
            src={`${process.env.PUBLIC_URL}/icons/${iconName}.png`}
            alt={altText}
            style={{ width: "60px", height: "60px" }}
          ></img>
        </p>
        <p
          style={{
            width: "324px",
            height: "44px",
            fontFamily: "Open Sans",
            fontSize: "16pt",
            lineHeight: "22px",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >{message}</p>
        <button
          className="btn btn-primary btn-lg shadow-none"
          onClick={(e) => onClick(e)}
        >
          Back
        </button>
      </div>
    </Fragment>
  );
};

export default OnDemandFailure;
