import React, { Fragment, useEffect, useState } from "react";

// Utilities
import axios from 'axios';

// Material components
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

// Material icons (https://mui.com/material-ui/material-icons/
import RefreshIcon from '@mui/icons-material/Refresh';

//Styles
import styles from './NewVersionChecker.module.css';

/**
 * This component is included in the Navbar.
 * It's re-rendering is triggered whenever the accessToken changes per useEffect(,[accesstoken])
 */
const NewVersionChecker = () => {
  const [buildInfo, setBuildInfo] = useState({running:'', available: ''});

  useEffect(() => {
    async function _getBuildNumber() {
      const result = await axios.get('index.html')
        .then(result => { return result; })
        .catch(err => {
          console.warn(`NewVersionChecker() unable to fetch latest revision: ${err}`);
        });
      if (!result) return;
      const regex = /<div class="revision-str">(\w+)</g;
      const parts = regex.exec(result?.data);
      const buildNbr = parts[1].slice(0,8);
      // nothing set yet, set both and return
      if (buildInfo.running === '') {
        setBuildInfo({running:buildNbr, available:buildNbr});
        return;
      }
      // if buildNbr changes, update buildInfo.available
      if (buildInfo.available !== buildNbr) {
        setBuildInfo({...buildInfo, available:buildNbr});
      }
    };

    // call this immediately, then once every 15 minutes
    _getBuildNumber();

    const interval = setInterval(() => {
      _getBuildNumber();
    }, 15*60*1000);  // check every 15 minutes (15*60*1000)
    return () => clearInterval(interval);
  }, [buildInfo]);

  const reloadSPA = () => {
    window.location.reload();
  };

  return (
    <Fragment>
      { buildInfo.running === buildInfo.available ? (
        <div className={styles.revision}>{buildInfo.running}</div>
      ) : (
        <div className={styles.reload}>
          <Tooltip title="click to Refresh IDgo Agent" arrow>
            <IconButton color="warning" onClick={reloadSPA}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
      }
    </Fragment>
  );
};

export default NewVersionChecker;
