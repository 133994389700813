import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

// Routing components
import PrivateRoute from './components/routing/PrivateRoute';

// Context
import { RelyingPartyProvider } from './context/RelyingPartyContext';

// Layout components
import Navbar from './components/layout/Navbar';

// Auth components
import Login from './components/auth/Login';

// User components
import ChangeEmail from './components/user/ChangeEmail';
import ChangeEmailSuccess from './components/user/ChangeEmailSuccess';
import ChangePassword from './components/user/ChangePassword';
import ChangePasswordSuccess from './components/user/ChangePasswordSuccess';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';

// Callers components
import CallerQueue from './components/callers/CallerQueue';
import CallerInformation from './components/callers/CallerInformation';
import OnDemandFailure from './components/callers/OnDemandFailure';

// popup windows
import ProofsReview from './components/windows/ProofsReview';

// For AAD authentications
import { MsalProvider } from '@azure/msal-react';

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <Router>
        <RelyingPartyProvider>
          <Navbar />
          <Toaster />
          <Switch>
            <Route        exact path='/'                              component={Login} />
            <Route        exact path='/forgot-password'               component={ForgotPassword} />
            <Route              path='/reset-password/:agentId/:oneTimeToken' component={ResetPassword} />
            <PrivateRoute exact path='/change-email'                  component={ChangeEmail} />
            <Route        exact path='/change-email-success'          component={ChangeEmailSuccess} />
            <PrivateRoute exact path='/change-password'               component={ChangePassword} />
            <Route        exact path='/change-password-success'       component={ChangePasswordSuccess} />
            <PrivateRoute exact path='/caller-queue'                  component={CallerQueue} />
            <PrivateRoute exact path='/caller-information'            component={CallerInformation} />
            <PrivateRoute exact path='/on-demand-failure'             component={OnDemandFailure} />
            <Route        exact path='/proofs-review'                 component={ProofsReview} />
          </Switch>
        </RelyingPartyProvider>
      </Router>
    </MsalProvider>
  );
};

export default App;
