import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// Components
import ResetPasswordSuccess from './ResetPasswordSuccess';
import PasswordInput from '../common/PasswordInput';
import InputValidation from '../common/InputValidation';
import Alert from '../layout/Alert';

// Utils
import { isValidPassword, onClickFocus } from '../../utils/validationCheck';
import {
  setValidPasswordInput,
  setInvalidPasswordInput,
} from '../../utils/toggleValidationFeedback';
import { updateResetPassword } from '../../utils/services';

//Styles
import styles from './ResetPassword.module.css';

const ResetPassword = () => {
  const { agentId, oneTimeToken } = useParams();
  const [formData, setFormData] = useState({ newPassword: ``, confirmPassword: `` });
  const [isUpdated, setIsUpdated] = useState(false);
  // Initially both passwords will match because both input field values are set to ''.
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const { newPassword, confirmPassword } = formData;
  const [alerts, setAlerts] = useState([]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validNewPassword     = isValidPassword(newPassword);
    const validConfirmPassword = isValidPassword(confirmPassword);

    if (validNewPassword) setValidPasswordInput(`new-password-input-group`);
    else setInvalidPasswordInput(`new-password-input-group`);

    if (validConfirmPassword) setValidPasswordInput(`confirm-password-input-group`);
    else setInvalidPasswordInput(`confirm-password-input-group`);

    if (!validNewPassword) return;
    if (!validConfirmPassword) return;

    if (newPassword !== confirmPassword) {
      setPasswordsMatch(false);
      setValidPasswordInput(`new-password-input-group`);
      setInvalidPasswordInput(`confirm-password-input-group`);
      return;
    }

    const result = await updateResetPassword(agentId, newPassword, oneTimeToken);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 8000);
      return;
    }

    setIsUpdated(true);
  };

  const ResetPasswordForm = (
    <>
      <InputValidation formName='passwordResetForm' />
      <div className={`row justify-content-center align-items-center ${styles.frame1}`}>
        <div className={`card mx-auto ${styles.frame2}`}>
          <div className={`text-center mx-auto ${styles.frame3}`}>
            <div className='card-body'>
              <div className={`text-left ${styles.alert}`}>
                <Alert alerts={alerts}/>
              </div>
              <div className={styles.frame5}>
                <h6>IDgo Agent</h6>
                <p className={`text-nowrap ${styles.header2}`}>
                  Create new password
                </p>
                <form
                  name='passwordResetForm'
                  method='post'
                  onSubmit={(e) => onSubmit(e)}
                  noValidate
                >
                  <div id='new-password-input-group' className={`form-group ${styles.passwordBox}`}>
                    <PasswordInput
                      id='new-password-input'
                      value={newPassword}
                      name='newPassword'
                      placeholder='Enter new password'
                      onChange={onChange}
                      onClick={(e) => onClickFocus(e)}
                      required
                      invalidMessage={`New password field is required`}
                    />
                  </div>
                  <div id='confirm-password-input-group' className={`form-group ${styles.passwordBox}`}>
                    <PasswordInput
                      id='confirm-password-input'
                      value={confirmPassword}
                      name='confirmPassword'
                      placeholder='Confirm new password'
                      onChange={onChange}
                      onClick={(e) => onClickFocus(e)}
                      required
                      invalidMessage={
                        passwordsMatch
                          ? `Confirm password field is required`
                          : `Confirm password must match new password`
                      }
                    />
                  </div>
                  <input
                    type='submit'
                    value='Save'
                    className='btn btn-primary btn-lg shadow-none'
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return isUpdated ? <ResetPasswordSuccess /> : ResetPasswordForm;
};

export default ResetPassword;