import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

// Helpers
import { setCallInProgress } from '../../utils/currentCallHelper'

//Styles
import styles from './CallerInformation.module.css';

// Utils
import { updateCallStatusByCallID as updateCallStatusByCallIDSvc } from '../../utils/services';
import { ctxValue } from '../../utils/config';
const subdomain = ctxValue('SUBDOMAIN');

const CallerInformation = (props) => {
  const history = useHistory();
  const { call, isOnDemand } = props.location.state;   // is is a risky approach to setting state...

  const untakeCall = (call) => {
    setCallInProgress(null);
    if (call?.callId) {
      updateCallStatusByCallIDSvc({callId: call.callId, subdomain, operation: 'untakecall'});
    }
    history.push({ pathname: '/caller-queue', hash: subdomain });
  };

  const finishCall = (call) => {
    setCallInProgress(null);
    if (!isOnDemand) {
      if (call?.callId) {
        updateCallStatusByCallIDSvc({callId: call.callId, subdomain, operation: 'finishcall'});
      }
    }
    history.push({ pathname: '/caller-queue', hash: subdomain });
  };

  const renderField = (field, index) => {
    return <div key={index}>
        <div className={styles.fieldLabel}>{ field.label }</div>
        <div className={styles.fieldValue}>{ field.value }</div>
      </div>
  };

  const renderAgentClaimData = (acd) => {
    if (!acd) return <></>;
    return <> { acd.map((field, index) => renderField(field, index)) } </>
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='mx-auto' style={{ width: '280px' }}>
          <div className='text-center'>
            <p style={{ marginBottom: '10px' }}>
              <img src={`${process.env.PUBLIC_URL}/icons/ic_check_mark_grn_lg.png`} alt='Authenticated' style={{ width: '55px', height: '50px' }}></img>
              <small>Authenticated</small>
            </p>
          </div>

          {renderAgentClaimData(call?.callerData?.agentClaimData)}

          {isOnDemand ? (
            <div className='text-center' style={{ marginBottom: '40px', marginTop: '20px' }}>
              <button className='btn btn-primary btn-lg shadow-none' onClick={() => finishCall(call)} >
                Complete
              </button>
            </div>
          ) : (
            <div style={{ marginBottom: '40px', marginTop: '20px' }}>
              <button className='btn btn-outline-primary btn-sm float-left shadow-none' onClick={() => untakeCall(call)} >
                Back
              </button>
              <button className='btn btn-primary btn-sm float-right shadow-none' onClick={() => finishCall(call)} >
                Complete
              </button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CallerInformation;